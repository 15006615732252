<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level">
                    <h2 class="title"> Preguntas frecuentes </h2>
                    <div class="page-next">
                        <nav aria-label="breadcrumb" class="d-inline-block">
                            <ul class="breadcrumb bg-white rounded shadow mb-0">
                                <li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Preguntas frecuentes</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
</div>
<!--Shape End-->

<!-- Start Terms & Conditions -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-9">
                <div class="card shadow border-0 rounded">
                    <div class="card-body">
                        <h4 class="card-title" style="margin-bottom: 50px;">Preguntas y respuestas de los clientes :</h4>
                        <div class="faq-content mt-4">
                            <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" closeOthers="true">
                                <ngb-panel>
                                    <ng-template ngbPanelTitle>
                                        <div class="border-0 bg-light text-dark" id="headingone">
                                            <h6 class="title mb-0"> ¿ Cómo llegar a Buena Vista Máncora ? </h6>
                                        </div>
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                        <h6 class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</h6>
                                    </ng-template>
                                </ngb-panel>

                                <ngb-panel>
                                    <ng-template ngbPanelTitle>
                                        <div class="border-0 bg-light text-dark" id="headingtwo">
                                            <h6 class="title mb-0"> ¿ Cual es el mejor momento del año para visitar Máncora ? </h6>
                                        </div>
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                        <h6 class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</h6>
                                    </ng-template>
                                </ngb-panel>
                                <ngb-panel>
                                    <ng-template ngbPanelTitle>
                                        <div class="border-0 bg-light text-dark" id="headingfour">
                                            <h6 class="title mb-0"> ¿ Estamos hubicados cerca al mar ? </h6>
                                        </div>
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                        <h6 class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</h6>
                                    </ng-template>
                                </ngb-panel>
                                <ngb-panel>
                                    <ng-template ngbPanelTitle>
                                        <div class="border-0 bg-light text-dark" id="headingfive">
                                            <h6 class="title mb-0"> ¿<span class="text-primary">Buena Vista Máncora</span> funciona todo el año ?
                                            </h6>
                                        </div>
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                        <h6 class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</h6>
                                    </ng-template>
                                </ngb-panel>
                                <ngb-panel>
                                    <ng-template ngbPanelTitle>
                                        <div class="border-0 bg-light text-dark" id="headingfive">
                                            <h6 class="title mb-0"> ¿ Cuanto tiempo hay de Buena Vista Máncora hasta el centro de la ciudad ?
                                            </h6>
                                        </div>
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                        <h6 class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</h6>
                                    </ng-template>
                                </ngb-panel>
                            </ngb-accordion>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End Terms & Conditions -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->