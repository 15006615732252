<!-- Hero Start -->
<section class="bg-half d-table w-100" id="home" style="background: url('assets/images/company/nosotrosPortada.jpg'); background-size: 100% 100%;">
    <div class="bg-overlay"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level title-heading">
                    <h1 class="display-4 title-dark text-white fw-bold mb-3 text-white title-dark title"> Nosotros </h1>
                    <h3 class="text-white para-desc mb-0 mx-auto" style="font-size: x-large;">Nos ubicamos a orillas del mar y a menos de cinco minutos del centro de la ciudad, visitenos y disfrute del incomparable sol de Máncora.</h3>
                    <div class="page-next">
                        <nav aria-label="breadcrumb" class="d-inline-block">
                            <ul class="breadcrumb bg-white rounded shadow mb-0">
                                <li class="breadcrumb-item"><a routerLink="/inicio">Inicio</a></li>
                                <!-- <li class="breadcrumb-item"><a href="javascript:void(0)">Company</a></li> -->
                                <li class="breadcrumb-item active" aria-current="page">Nosotros</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
</div>
<!-- Hero End -->

<section class="section">
    <div class="container">
        <div class="row align-items-center" id="counter">
            <div class="col-md-6">
                <img src="assets/images/company/misionVision.jpg" class="img-fluid" alt="">
            </div>
            <!--end col-->

            <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="ms-lg-4">
                    <div class="d-flex mb-4">
                        <span class="text-primary h1 mb-0"><span class="counter-value display-1 fw-bold" [CountTo]="anioActual-2009" [from]="0"
                  [duration]="4"></span> </span>
                        <h3 class="align-self-end ms-2" style="font-size: x-large;">Años De Experiencia</h3>
                    </div>
                    <div class="section-title">
                        <h2 class="mb-4">Nuestra Misión</h2>
                        <p style="font-size: medium; font-size: large;">En el hotel <span class="text-primary fw-bold" style="font-family: 'Tillana'">Buena Vista</span> brindamos un servicio hotelero de excelencia, desarrollando productos y servicios que garantizan la satisfacción, tranquilidad a nuestros
                            huéspedes en su estadía, mediante un trato individualizado por un personal altamente motivado, buscando superar las expectativas de nuestros huéspedes. Contamos con un talento humano comprometido, en permanente desarrollo y
                            mejoramiento continuo, que nos permite ampliar y mantener el mercado brindando total seguridad a empleados, clientes, proveedores, socios y comunidad en general.</p>
                        <h2 class="mb-4">Nuestra Visión</h2>
                        <p style="font-size: medium; font-size: large;">Queremos que cada persona que visite Máncora sea partícipe de la energía positiva que brinda el Hotel <span class="text-primary fw-bold" style="font-family: 'Tillana'">Buena Vista</span>, convertirnos en una empresa con sólido
                            prestigio hotelero a nivel local, nacional e internacional, fomentando el desarrollo turístico y familiar de la provincia de Máncora, ofreciendo un servicio personalizado que permita una grata estadía de nuestros huéspedes.
                            Siempre comprometidos a través de la mejora continua dentro de un marco de cultura, valores y hospitalidad mancoreña.</p>
                        <a href="javascript:void(0)" routerLink="/inicio/contacto" class="btn btn-primary mt-3" style="font-size: large;">Contáctenos</a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <!-- <div class="container mt-5">
      <app-clients-logo></app-clients-logo>
    </div> -->
    <!--end container-->
</section>
<!--end section-->



<section class="section bg-light">
    <div class="container mt-100 mt-60" style="margin-top: -15px;">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-6 order-1 order-md-2">
                <img src="assets/images/travel/vistaLinda.jpg" class="img-fluid rounded shadow-md" alt="">
            </div>
            <!--end col-->

            <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="section-title me-lg-5">
                    <h4 class="title mb-4">Preguntas frecuentes: <span class="text-primary fw-bold">Buena Vista Máncora</span></h4>
                    <h3 style="font-size: large; color: blue;">Complejo hotelero <span class="text-primary fw-bold">siempre abierto !!!</span></h3>
                </div>

                <div class="faq-content mt-4 pt-2">
                    <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" closeOthers="true">
                        <ngb-panel cardClass="border-0 rounded shadow mb-2">
                            <ng-template ngbPanelTitle>
                                <div class="border-0 bg-light" id="headingtwo">
                                    <h4 class="title mb-0 text-dark">¿Qué ofrece Buena Vista Máncora?
                                    </h4>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <p style="font-size: large;">Habitaciones para personas con discapacidad, aire acondicionado, atmósfera relajante y cálida, ubicación a orillas del Mar que hacen realmente a Buena Vista la mejor opción de alojamiento en Máncora</p>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel cardClass="border-0 rounded shadow mb-2">
                            <ng-template ngbPanelTitle>
                                <div class="border-0 bg-light" id="headingtwo">
                                    <h4 class="title mb-0 text-dark">¿Buena Vista Máncora se encuentra cerca del mar?
                                    </h4>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <p style="font-size: large;">Nuestra ubicación esta en Mancora al borde del mar y a menos de cinco minutos a pie del centro de Mancora, pase unos días gozando del incomparable mar de Mancora
                                </p>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel cardClass="border-0 rounded shadow mb-2">
                            <ng-template ngbPanelTitle>
                                <div class="border-0 bg-light" id="headingtwo">
                                    <h4 class="title mb-0 text-dark">¿Con qué comodidades cuenta Buena Vista Máncora?
                                    </h4>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <p style="font-size: large;">Buena Vista Máncora cuenta con agua caliente, internet inalambrico, tv cable, terrazas vista al mar, bellos jardines, etc. En los cuales podemos alojar a grupos grandes como familias, estudiantes, parejas, amigos, etc.</p>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel cardClass="border-0 rounded shadow mb-2">
                            <ng-template ngbPanelTitle>
                                <div class="border-0 bg-light" id="headingtwo">
                                    <h4 class="title mb-0 text-dark">Información adicional de Buena Vista Máncora
                                    </h4>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <p style="font-size: large;">La construcción de nuestros departamentos son una edificación tradicional que harán de su permanencia muy placentera. Contamos con una piscina grande de 3 niveles la cual le hara pasar unos días gozando del incomparable
                                    sol de Mancora y de un total relax arrullado por el sonido del Mar.</p>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>

                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->

<!-- Start -->
<section class="section bg-light pt-0">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="video-solution-cta position-relative" style="z-index: 1;">
                    <div class="position-relative">
                        <img src="assets/images/modelo.jpeg" class="img-fluid rounded-md shadow-lg" alt="">
                        <div class="play-icon">
                            <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" class="play-btn video-play-icon">
                                <i class="mdi mdi-play text-primary rounded-circle bg-white shadow-lg"></i>
                            </a>
                            <ng-template #content let-modal>
                                <div class="modal-header">
                                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                                </div>
                                <div class="modal-body">
                                    <!-- <youtube-player [videoId]="'yba7hPeTSjk'" height="450" width="780">
                    </youtube-player> -->
                                    <iframe height="450" width="780" src="https://www.youtube.com/embed/m2nhc9kfF1k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                    </iframe>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row -->
        <div class="feature-posts-placeholder bg-primary"></div>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->