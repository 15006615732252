<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home" style="background: url('assets/images/hotel/portadaServicios2.jpg'); background-size: 100% 100%;">
    <div class="bg-overlay"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level">
                    <h1 class="display-4 title-dark text-white fw-bold mb-3 text-white title-dark title"> Servicios </h1>
                    <h3 class="text-white para-desc mb-0 mx-auto" style="font-size: x-large;">En Buena Vista Máncora contamos con los servicios que toda estadia merece.</h3>
                    <div class="page-next">
                        <nav aria-label="breadcrumb" class="d-inline-block">
                            <ul class="breadcrumb bg-white rounded shadow mb-0">
                                <li class="breadcrumb-item"><a routerLink="/inicio">Inicio</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Services</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
</div>
<!--Shape End-->

<!-- Feature Start -->
<section class="section">
    <div class="container">
        <app-services [servicesData]="servicesData"></app-services>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Feature Start -->

<!-- Project Start -->
<section class="section bg-light">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h1 class="mb-4">Tus mejores momentos, nuestros servicios</h1>
                    <h3 style="font-size: x-large; color: blue;">En <span class="text-primary fw-bold">Buena Vista Máncora</span> cuentas con los siguientes servicios.</h3>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
            <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="card work-container work-modern position-relative overflow-hidden shadow rounded border-0">
                    <div class="card-body p-0">
                        <img src="assets/images/work/1.jpg" class="img-fluid rounded" alt="work-image">
                        <div class="overlay-work bg-dark"></div>
                        <div class="content">
                            <a class="title text-white d-block fw-bold">Piscina frente al mar</a>
                            <h6 class="text-light">Adultos y niños</h6>
                        </div>
                        <!-- <div class="client">
                <small class="text-light user d-block"><i class="mdi mdi-account"></i> Calvin Carlo</small>
                <small class="text-light date"><i class="mdi mdi-calendar-check"></i> 13th August, 2019</small>
              </div> -->
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="card work-container work-modern position-relative overflow-hidden shadow rounded border-0">
                    <div class="card-body p-0">
                        <img src="assets/images/work/2.jpg" class="img-fluid rounded" alt="work-image">
                        <div class="overlay-work bg-dark"></div>
                        <div class="content">
                            <a class="title text-white d-block fw-bold">Mesa de billar</a>
                            <h6 class="text-light">Diviertete con tus amigos</h6>
                        </div>
                        <!-- <div class="client">
                <small class="text-light user d-block"><i class="mdi mdi-account"></i> Calvin Carlo</small>
                <small class="text-light date"><i class="mdi mdi-calendar-check"></i> 13th August, 2019</small>
              </div> -->
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="card work-container work-modern position-relative overflow-hidden shadow rounded border-0">
                    <div class="card-body p-0">
                        <img src="assets/images/work/3.jpg" class="img-fluid rounded" alt="work-image">
                        <div class="overlay-work bg-dark"></div>
                        <div class="content">
                            <a class="title text-white d-block fw-bold">Terraza frente al mar</a>
                            <h6 class="text-light">La mejor vista solo en Buena Vista</h6>
                        </div>
                        <!-- <div class="client">
                <small class="text-light user d-block"><i class="mdi mdi-account"></i> Calvin Carlo</small>
                <small class="text-light date"><i class="mdi mdi-calendar-check"></i> 13th August, 2019</small>
              </div> -->
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="card work-container work-modern position-relative overflow-hidden shadow rounded border-0">
                    <div class="card-body p-0">
                        <img src="assets/images/work/4.jpg" class="img-fluid rounded" alt="work-image">
                        <div class="overlay-work bg-dark"></div>
                        <div class="content">
                            <a class="title text-white d-block fw-bold">Estacionamiento</a>
                            <h6 class="text-light">Amplio espacio de facil acceso</h6>
                        </div>
                        <!-- <div class="client">
                <small class="text-light user d-block"><i class="mdi mdi-account"></i> Calvin Carlo</small>
                <small class="text-light date"><i class="mdi mdi-calendar-check"></i> 13th August, 2019</small>
              </div> -->
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <!-- <div class="container mt-100 mt-60">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title">
            <h4 class="title mb-4">See everything about your employee at one place.</h4>
            <p class="text-muted para-desc mx-auto">Start working with <span class="text-primary fw-bold">Landrick</span>
              that can provide everything you need to generate awareness, drive traffic, connect.</p>
  
            <div class="mt-4">
              <a href="javascript:void(0)" class="btn btn-primary mt-2 me-3">Get Started Now</a>
              <a href="javascript:void(0)" class="btn btn-outline-primary mt-2">Free Trial</a>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!--end container-->
</section>
<!--end section-->
<!-- Project End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->