<div class="col-lg-12">
    <form class="p-4 shadow bg-white rounded" autocomplete="off" [formGroup]="checkDisponibilidadForm" (ngSubmit)="buscarDisponibilidadHabitacion()">
        <h2 class="mb-3">Prepare su reserva</h2>
        <div class="row text-start">
            <div ngClass="col-lg-12 col-md-12">
                <div class="mb-6 mb-lg-0">
                    <label class="form-label" style="font-size: large;"> Check in - Check out : </label>
                    <input class="form-control" style="text-align: center;" type="text" formControlName="picker" id="checkin" placeholder="Fecha de Ingreso - Fecha de salida :" mwlFlatpickr mode="range" minDate="today" (ngModelChange)="changeFecha($event)">
                </div>
            </div>
            <!--end col-->

            <div ngClass="col-md-12">
                <div class="row align-items-center">

                    <div ngClass="col-md-12 mt-lg-4 pt-2 pt-lg-1">
                        <div class="d-grid">
                            <input type="submit" id="search" name="search" class="searchbtn btn btn-blue" style="font-size: large;" value="Buscar">
                        </div>
                    </div>
                    <!--end col-->
                </div>
            </div>
        </div>

    </form>
    <div class="col-md-12 mt-lg-4 pt-2 pt-lg-1" *ngIf="habilitarBtnReserva">
        <div class="d-grid">
            <button class="searchbtn btn btn-primary" (click)="reservar()">Reservar</button>
        </div>
    </div>
</div>