<section class="section pt-5 pt-sm-0 pt-md-4 bg-light">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h1>Relajación que nos diferencia</h1>
                    <h3 style="font-size: x-large; color: blue;">En <span class="text-primary fw-bold">Buena Vista Máncora</span> pasa los mejores momentos frente al mar.
                    </h3>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2" *ngFor="let item of cartillaData; let i=index">
                <div class="card border-0 work-container work-modern position-relative d-block overflow-hidden rounded">
                    <div class="card-body p-0">
                        <img src="{{item.image }}" class="img-fluid" alt="work-image">
                        <div class="overlay-work bg-dark"></div>
                        <div class="content">
                            <h5 class="mb-0"><a class="text-white title">{{item.title}}</a></h5>
                            <h6 class="text-light tag mb-0">{{item.category}}</h6>
                        </div>
                        <div class="icons text-center">
                            <a (click)="open(i)" class="text-primary work-icon bg-white d-inline-block rounded-pill mfp-image">
                                <i-feather name="camera" class="fea icon-sm"></i-feather>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--end container-->
</section>