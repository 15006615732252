export const environment = {
  production: true,
  url_base_backend: '//reserva.buenavistamancora.com/api',
  url_root_backend: '//reserva.buenavistamancora.com',
  url_root_frontend: '//www.buenavistamancora.com',
  // url_base_backend: 'http://localhost:8000/api',
  // url_root_backend: 'http://localhost:8000',
  // url_root_frontend: 'http://localhost:4200',

  key_acceso_backend: 'Rdt6uToQ.10xQDsISuxkIHMGvs4ZOFRt18Is8GEta'
  // key_acceso_backend: '1KsNX853.fuxE5SoFwVmRowb3gnrx5tyzHTL8R59h'

};
