<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level">
                    <h2 class="title"> Políticas de privacidad</h2>
                    <ul class="list-unstyled mt-4">
                        <li class="list-inline-item h6 date text-muted">
                            <h6 class="text-dark">Última revisión</h6> 23 de Diciembre de 2021
                        </li>
                    </ul>
                    <div class="page-next">
                        <nav aria-label="breadcrumb" class="d-inline-block">
                            <ul class="breadcrumb bg-white rounded shadow mb-0">
                                <li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Póliticas</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
</div>
<!--Shape End-->

<!-- Start Privacy -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-9">
                <div class="card shadow rounded border-0">
                    <div class="card-body">
                        <h3 class="card-title">Póliticas de cancelacion de reserva :</h3>
                        <h6 class="text-muted">A).- Si la cancelación se produce con 30 días o más de anticipación a la fecha de Check In se reembolsará el 90% de la suma abonada menos los gastos de transferencia que estos ocasionen.</h6>
                        <h6 class="text-muted">B).- Si la cancelación se diera entre 20 y 11 días previos a la fecha de llegada, hay una penalidad del 20% del monto total de la reserva original, menos los gastos de transferencia que estos ocasionen.</h6>
                        <h6 class="text-muted">C).- Si la cancelación se diera entre 0 y 2 días previos a la fecha de llegada, hay una penalidad del 100% del monto total de la reserva original.</h6>
                        <h6 class="text-muted">D).- Si la reserva se hace directamente en el hotel y solicita disminuir su estadía y/o cantidad de huéspedes, no abra reembolso alguno.</h6>
                        <h6 class="text-muted">E).- Si después de realizada la reserva desea disminuir la cantidad de noches y/o huéspedes no habrá reducción de costo ni reembolso.</h6>
                        <h6 class="text-muted">F).- En el caso no se presenten los pasajeros a la reserva en el día concertado, será considerado como un “No Show”. En estos casos no hay devolución de dinero ni traslado de reserva.</h6>
                        <h6 class="text-muted">G).- Ante desastres naturales, pandemias u otros disturbios que impidan que los viajeros se desplacen desde o hacia el lugar de destino, o que no permitan garantizar una estancia segura se procede con el reembolso del 50% de la suma abonada o postergación para una nueva fecha, previa disponibilidad y tarifas del establecimiento.</h6>
                        <h6 class="text-muted">H).- Enfermedad o lesión grave inesperada que afecte al anfitrión o aun miembro del grupo de viajeros deberá presentar un certificado médico en el que se confirma que la persona no puede hospedarse o viajar debido a una lesión grave e inesperada. Además, su fecha de expedición deberá ser posterior a la fecha en que se hizo la reservación y tendrá que enviarlo en un plazo de 14 días desde la cancelación para una reprogramación de su estadía (No hay reembolso).</h6>
                        <h6 class="text-muted">I).- Si se solicita un cambio de fecha, habrá un cobro de penalidad del 10%, solo en caso que el cambio sea por temas de restricciones del gobierno no habrá cobro adicional.</h6>

                        <h6 class="text-muted">Atentamente</h6>

                        <h5 class="card-title">La Gerencia</h5>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End Privacy -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->