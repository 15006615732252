<div class="back-to-home rounded d-none d-sm-block">
    <a routerLink="/inicio" class="btn btn-icon btn-soft-primary">
        <i-feather name="home" class="icons"></i-feather>
    </a>
</div>

<!-- ERROR PAGE -->
<section class="bg-home d-flex align-items-center">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12 text-center">
                <!-- <img src="assets/images/404.svg" class="img-fluid" alt=""> -->
                <!-- <img src="assets/images/404.svg" class="img-fluid" alt=""> -->
                <div class="text-uppercase mt-4 display-3">Oh ! no</div>
                <div class="text-capitalize text-dark mb-4 error-page">Página no encontrada</div>
                <p class="text-muted para-desc mx-auto">Esta dirección web en
                    <span class="text-primary fw-bold" style="font-family: 'Tillana'">
              Buena Vista Máncora
            </span> no existe.</p>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
            <div class="col-md-12 text-center">
                <a input type="button" value="Go back!" onclick="history.go(-1)" class="btn btn-outline-primary mt-4">Ir atrás</a>
                <a routerLink="/inicio" class="btn btn-primary mt-4 ms-2">Ir a inicio</a>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- ERROR PAGE -->