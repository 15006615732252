<!-- Hero Start -->
<section class="bg-half-260 w-100 d-table" data-jarallax='{"speed": 0.5}' style="background: url('assets/images/hotel/eventoPortada2.jpg') center center; background-size: 100% 100%;" id="home">
    <!-- <div class="bg-overlay bg-primary bg-gradient" style="opacity: 0.85;"></div> -->
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10 text-center">
                <div class="title-heading">
                    <!-- <h4 class="text-success mb-3">12 de Octubre, 2021</h4> -->
                    <h1 class="display-4 title-dark text-white fw-bold mb-3">Eventos En Buena Vista Máncora</h1>
                    <h3 class="para-desc title-dark mx-auto text-light" style="font-size: x-large;">En buena Vista Máncora contamos con áreas comunes idóneas para organizar y celebrar diferentes tipos de eventos. Nuestros años de experiencia en el rubro nos avalan para ofrecerte las condiciones que son requeridas por los clientes
                        mas exigentes.</h3>

                    <!-- <div class="row">
            <div class="col-md-12 text-center">
              <div id="eventdown">
                <ul class="count-down list-unstyled">
                  <li id="days" class="count-number list-inline-item px-4">{{_days}}<p class="count-head">Days</p>
                  </li>
                  <li id="hours" class="count-number list-inline-item px-4">{{_hours}}<p class="count-head">Hours</p>
                  </li>
                  <li id="mins" class="count-number list-inline-item px-4">{{_minutes}}<p class="count-head">Minutes</p>
                  </li>
                  <li id="secs" class="count-number list-inline-item px-4">{{_seconds}}<p class="count-head">Seconds</p>
                  </li>
                  <li id="end" class="h1"></li>
                </ul>
              </div>
            </div>
          </div> -->

                    <!-- <a href="javascript:void(0)" class="btn btn-success mt-2 me-2"><i class="uil uil-ticket"></i> Buy Tickets</a> -->
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- <div class="position-relative">
  <div class="shape overflow-hidden text-footer">
    <div class="text-center bg-white p-4">
      <h5 class="text-dark mb-0">Next Event : 15th March, 2021</h5>
    </div>
  </div>
</div> -->
<!-- Hero End -->

<!-- About START -->
<section class="section border-top">
    <div class="container">
        <div class="card rounded shadow border-0 bg-light overflow-hidden">
            <div class="row no-gutters align-items-center">
                <div class="col-lg-6">
                    <img src="assets/images/event/b04.jpeg" class="img-fluid" alt="">
                </div>
                <!--end col-->
                <div class="col-lg-6">
                    <div class="card-body section-title p-md-5">
                        <h1 class="mb-4">Matrimonios & Ceremonias</h1>
                        <p style="font-size: large;">Start working with Landrick that can provide everything you need to generate awareness, drive traffic, connect. Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap4 html page
                            managing conversion centered bootstrap4 html page.</p>
                        <div class="mt-4 pt-2">
                            <!-- <a href="javascript:void(0)" class="btn btn-outline-primary">Ver detalle <i
                  class="mdi mdi-chevron-right"></i></a> -->
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end card-->
    </div>
    <!--end container-->
    <div class="container" style="margin-top: 50px;">
        <div class="card rounded shadow border-0 bg-light overflow-hidden">
            <div class="row no-gutters align-items-center">
                <div class="col-lg-6">
                    <img src="assets/images/event/b03.jpg" class="img-fluid" alt="">
                </div>
                <!--end col-->
                <div class="col-lg-6">
                    <div class="card-body section-title p-md-5">
                        <h1 class="mb-4">Eventos De Confraternidad</h1>
                        <p style="font-size: large;">Start working with Landrick that can provide everything you need to generate awareness, drive traffic, connect. Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap4 html page
                            managing conversion centered bootstrap4 html page.</p>
                        <div class="mt-4 pt-2">
                            <!-- <a href="javascript:void(0)" class="btn btn-outline-primary">Ver detalle <i
                  class="mdi mdi-chevron-right"></i></a> -->
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end card-->
    </div>
    <!--end container-->
    <div class="container" style="margin-top: 50px;">
        <div class="card rounded shadow border-0 bg-light overflow-hidden">
            <div class="row no-gutters align-items-center">
                <div class="col-lg-6">
                    <img src="assets/images/event/b02.jpg" class="img-fluid" alt="">
                </div>
                <!--end col-->
                <div class="col-lg-6">
                    <div class="card-body section-title p-md-5">
                        <h1 class="mb-4">Reuniones Familiares</h1>
                        <p style="font-size: large;">Start working with Landrick that can provide everything you need to generate awareness, drive traffic, connect. Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap4 html page
                            managing conversion centered bootstrap4 html page.</p>
                        <div class="mt-4 pt-2">
                            <!-- <a href="javascript:void(0)" class="btn btn-outline-primary">Ver detalle <i
                  class="mdi mdi-chevron-right"></i></a> -->
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end card-->
    </div>
    <!--end container-->
    <div class="container" style="margin-top: 50px;">
        <div class="card rounded shadow border-0 bg-light overflow-hidden">
            <div class="row no-gutters align-items-center">
                <div class="col-lg-6">
                    <img src="assets/images/event/b05.jpeg" class="img-fluid" alt="">
                </div>
                <!--end col-->
                <div class="col-lg-6">
                    <div class="card-body section-title p-md-5">
                        <h1 class="mb-4">Almuerzos & Cenas</h1>
                        <p style="font-size: large;">Start working with Landrick that can provide everything you need to generate awareness, drive traffic, connect. Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap4 html page
                            managing conversion centered bootstrap4 html page.</p>
                        <div class="mt-4 pt-2">
                            <!-- <a href="javascript:void(0)" class="btn btn-outline-primary">Ver detalle <i
                  class="mdi mdi-chevron-right"></i></a> -->
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end card-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- About End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-light">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
    </div>
</div>
<!--Shape End-->

<!-- Speakers Start -->
<!-- <section class="section bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Our Speakers</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3 col-md-6 mt-4 pt-2">
        <div class="card team text-center rounded border-0">
          <div class="card-body">
            <div class="position-relative">
              <img src="assets/images/client/01.jpg" class="img-fluid avatar avatar-ex-large rounded-circle shadow"
                alt="">
              <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                <li class="list-inline-item me-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item me-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item me-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item me-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
              </ul>
            </div>
            <div class="content pt-3">
              <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Ronny Jofra</a></h5>
              <small class="designation text-muted">Organizer</small>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 mt-4 pt-2">
        <div class="card team text-center rounded border-0">
          <div class="card-body">
            <div class="position-relative">
              <img src="assets/images/client/04.jpg" class="img-fluid avatar avatar-ex-large rounded-circle shadow"
                alt="">
              <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                <li class="list-inline-item me-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item me-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item me-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item me-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
              </ul>
            </div>
            <div class="content pt-3">
              <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Micheal Carlo</a></h5>
              <small class="designation text-muted">Event Manager</small>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 mt-4 pt-2">
        <div class="card team text-center rounded border-0">
          <div class="card-body">
            <div class="position-relative">
              <img src="assets/images/client/03.jpg" class="img-fluid avatar avatar-ex-large rounded-circle shadow"
                alt="">
              <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                <li class="list-inline-item me-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item me-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item me-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item me-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
              </ul>
            </div>
            <div class="content pt-3">
              <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Aliana Rosy</a></h5>
              <small class="designation text-muted">Motivator</small>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 mt-4 pt-2">
        <div class="card team text-center rounded border-0">
          <div class="card-body">
            <div class="position-relative">
              <img src="assets/images/client/02.jpg" class="img-fluid avatar avatar-ex-large rounded-circle shadow"
                alt="">
              <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                <li class="list-inline-item me-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item me-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item me-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item me-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
              </ul>
            </div>
            <div class="content pt-3">
              <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Sofia Razaq</a></h5>
              <small class="designation text-muted">Speaker</small>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</section> -->
<!--end section-->
<!-- Speakers End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
    </div>
</div>
<!--Shape End-->

<!-- Schedule Start -->
<!-- <section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Event Schedules</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
    </div>

    <div class="row mt-4 pt-2 justify-content-center">

      <ul ngbNav #nav="ngbNav"
        class="nav nav-pills rounded nav-justified flex-column flex-sm-row col-lg-8 col-md-12 text-center">
        <li ngbNavItem>
          <a ngbNavLink class="rounded">
            <div class="text-center pt-1 pb-1">
              <h6 class="mb-0">First Day</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="col-lg-6 mt-4 pt-2">
                <div class="card event-schedule rounded border">
                  <div class="card-body">
                    <div class="media">
                      <ul class="date text-center text-primary me-3 mb-0 list-unstyled">
                        <li class="day font-weight-bold mb-2">11</li>
                        <li class="month font-weight-bold">OCT</li>
                      </ul>
                      <div class="media-body content">
                        <h4><a href="javascript:void(0)" class="text-dark title">Digital Conference Event Intro</a>
                        </h4>
                        <p class="text-muted location-time"><span class="text-dark h6">Address:</span> Hall 3,
                          Sinchang-dong, Kwangju, <span class="text-danger">South Korea</span> <br> <span
                            class="text-dark h6">Time:</span> 10:30AM to 11:15AM</p>
                        <a [ngxScrollTo]="'#tickets'" class="btn btn-sm btn-outline-primary mouse-down">Buy Ticket</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 mt-4 pt-2">
                <div class="card event-schedule rounded border">
                  <div class="card-body">
                    <div class="media">
                      <ul class="date text-center text-primary me-3 mb-0 list-unstyled">
                        <li class="day font-weight-bold mb-2">11</li>
                        <li class="month font-weight-bold">OCT</li>
                      </ul>
                      <div class="media-body content">
                        <h4><a href="javascript:void(0)" class="text-dark title">Conference On User Interface</a>
                        </h4>
                        <p class="text-muted location-time"><span class="text-dark h6">Address:</span> Hall 3,
                          Sinchang-dong, Kwangju, <span class="text-danger">South Korea</span> <br> <span
                            class="text-dark h6">Time:</span> 11:15AM to 12:30PM</p>
                        <a [ngxScrollTo]="'#tickets'" class="btn btn-sm btn-outline-primary mouse-down">Buy Ticket</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 mt-4 pt-2">
                <div class="card event-schedule rounded border">
                  <div class="card-body">
                    <div class="media">
                      <ul class="date text-center text-primary me-3 mb-0 list-unstyled">
                        <li class="day font-weight-bold mb-2">11</li>
                        <li class="month font-weight-bold">OCT</li>
                      </ul>
                      <div class="media-body content">
                        <h4><a href="javascript:void(0)" class="text-dark title">Business World Event Intro</a></h4>
                        <p class="text-muted location-time"><span class="text-dark h6">Address:</span> Hall 3,
                          Sinchang-dong, Kwangju, <span class="text-danger">South Korea</span> <br> <span
                            class="text-dark h6">Time:</span> 12:30PM to 01:00PM</p>
                        <a [ngxScrollTo]="'#tickets'" class="btn btn-sm btn-outline-primary mouse-down">Buy Ticket</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 mt-4 pt-2">
                <div class="card event-schedule rounded border">
                  <div class="card-body">
                    <div class="media">
                      <ul class="date text-center text-primary me-3 mb-0 list-unstyled">
                        <li class="day font-weight-bold mb-2">11</li>
                        <li class="month font-weight-bold">OCT</li>
                      </ul>
                      <div class="media-body content">
                        <h4><a href="javascript:void(0)" class="text-dark title">Business Conference for
                            professional</a></h4>
                        <p class="text-muted location-time"><span class="text-dark h6">Address:</span> Hall 3,
                          Sinchang-dong, Kwangju, <span class="text-danger">South Korea</span> <br> <span
                            class="text-dark h6">Time:</span> 01:00PM to 02:15PM</p>
                        <a [ngxScrollTo]="'#tickets'" class="btn btn-sm btn-outline-primary mouse-down">Buy Ticket</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
        <li ngbNavItem>
          <a ngbNavLink class="rounded">
            <div class="text-center pt-1 pb-1">
              <h6 class="mb-0">Second Day</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="col-lg-6 mt-4 pt-2">
                <div class="card event-schedule rounded border">
                  <div class="card-body">
                    <div class="media">
                      <ul class="date text-center text-primary me-3 mb-0 list-unstyled">
                        <li class="day font-weight-bold mb-2">12</li>
                        <li class="month font-weight-bold">OCT</li>
                      </ul>
                      <div class="media-body content">
                        <h4><a href="javascript:void(0)" class="text-dark title">Digital Conference Event Intro</a>
                        </h4>
                        <p class="text-muted location-time"><span class="text-dark h6">Address:</span> Hall 1,
                          Seo-gu,
                          Kwangju, <span class="text-danger">South Korea</span> <br> <span
                            class="text-dark h6">Time:</span> 10:30AM to 11:15AM</p>
                        <a [ngxScrollTo]="'#tickets'" class="btn btn-sm btn-outline-primary mouse-down">Buy Ticket</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 mt-4 pt-2">
                <div class="card event-schedule rounded border">
                  <div class="card-body">
                    <div class="media">
                      <ul class="date text-center text-primary me-3 mb-0 list-unstyled">
                        <li class="day font-weight-bold mb-2">12</li>
                        <li class="month font-weight-bold">OCT</li>
                      </ul>
                      <div class="media-body content">
                        <h4><a href="javascript:void(0)" class="text-dark title">Conference On User Interface</a>
                        </h4>
                        <p class="text-muted location-time"><span class="text-dark h6">Address:</span> Hall 1,
                          Seo-gu,
                          Kwangju, <span class="text-danger">South Korea</span> <br> <span
                            class="text-dark h6">Time:</span> 11:15AM to 12:30PM</p>
                        <a [ngxScrollTo]="'#tickets'" class="btn btn-sm btn-outline-primary mouse-down">Buy Ticket</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 mt-4 pt-2">
                <div class="card event-schedule rounded border">
                  <div class="card-body">
                    <div class="media">
                      <ul class="date text-center text-primary me-3 mb-0 list-unstyled">
                        <li class="day font-weight-bold mb-2">12</li>
                        <li class="month font-weight-bold">OCT</li>
                      </ul>
                      <div class="media-body content">
                        <h4><a href="javascript:void(0)" class="text-dark title">Business World Event Intro</a></h4>
                        <p class="text-muted location-time"><span class="text-dark h6">Address:</span> Hall 1,
                          Seo-gu,
                          Kwangju, <span class="text-danger">South Korea</span> <br> <span
                            class="text-dark h6">Time:</span> 12:30PM to 01:00PM</p>
                        <a [ngxScrollTo]="'#tickets'" class="btn btn-sm btn-outline-primary mouse-down">Buy Ticket</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 mt-4 pt-2">
                <div class="card event-schedule rounded border">
                  <div class="card-body">
                    <div class="media">
                      <ul class="date text-center text-primary me-3 mb-0 list-unstyled">
                        <li class="day font-weight-bold mb-2">12</li>
                        <li class="month font-weight-bold">OCT</li>
                      </ul>
                      <div class="media-body content">
                        <h4><a href="javascript:void(0)" class="text-dark title">Business Conference for
                            professional</a></h4>
                        <p class="text-muted location-time"><span class="text-dark h6">Address:</span> Hall 1,
                          Seo-gu,
                          Kwangju, <span class="text-danger">South Korea</span> <br> <span
                            class="text-dark h6">Time:</span> 01:00PM to 02:15PM</p>
                        <a [ngxScrollTo]="'#tickets'" class="btn btn-sm btn-outline-primary mouse-down">Buy Ticket</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>

        <li ngbNavItem>
          <a ngbNavLink class="rounded">
            <div class="text-center pt-1 pb-1">
              <h6 class="mb-0">Third Day</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="col-lg-6 mt-4 pt-2">
                <div class="card event-schedule rounded border">
                  <div class="card-body">
                    <div class="media">
                      <ul class="date text-center text-primary me-3 mb-0 list-unstyled">
                        <li class="day font-weight-bold mb-2">13</li>
                        <li class="month font-weight-bold">OCT</li>
                      </ul>
                      <div class="media-body content">
                        <h4><a href="javascript:void(0)" class="text-dark title">Digital Conference Event Intro</a>
                        </h4>
                        <p class="text-muted location-time"><span class="text-dark h6">Address:</span> Hall 4,
                          Gwangsan-gu, Kwangju, <span class="text-danger">South Korea</span> <br> <span
                            class="text-dark h6">Time:</span> 10:30AM to 11:15AM</p>
                        <a [ngxScrollTo]="'#tickets'" class="btn btn-sm btn-outline-primary mouse-down">Buy Ticket</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 mt-4 pt-2">
                <div class="card event-schedule rounded border">
                  <div class="card-body">
                    <div class="media">
                      <ul class="date text-center text-primary me-3 mb-0 list-unstyled">
                        <li class="day font-weight-bold mb-2">13</li>
                        <li class="month font-weight-bold">OCT</li>
                      </ul>
                      <div class="media-body content">
                        <h4><a href="javascript:void(0)" class="text-dark title">Conference On User Interface</a></h4>
                        <p class="text-muted location-time"><span class="text-dark h6">Address:</span> Hall 4,
                          Gwangsan-gu, Kwangju, <span class="text-danger">South Korea</span> <br> <span
                            class="text-dark h6">Time:</span> 11:15AM to 12:30PM</p>
                        <a [ngxScrollTo]="'#tickets'" class="btn btn-sm btn-outline-primary mouse-down">Buy Ticket</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 mt-4 pt-2">
                <div class="card event-schedule rounded border">
                  <div class="card-body">
                    <div class="media">
                      <ul class="date text-center text-primary me-3 mb-0 list-unstyled">
                        <li class="day font-weight-bold mb-2">13</li>
                        <li class="month font-weight-bold">OCT</li>
                      </ul>
                      <div class="media-body content">
                        <h4><a href="javascript:void(0)" class="text-dark title">Business World Event Intro</a></h4>
                        <p class="text-muted location-time"><span class="text-dark h6">Address:</span> Hall 4,
                          Gwangsan-gu, Kwangju, <span class="text-danger">South Korea</span> <br> <span
                            class="text-dark h6">Time:</span> 12:30PM to 01:00PM</p>
                        <a [ngxScrollTo]="'#tickets'" class="btn btn-sm btn-outline-primary mouse-down">Buy Ticket</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 mt-4 pt-2">
                <div class="card event-schedule rounded border">
                  <div class="card-body">
                    <div class="media">
                      <ul class="date text-center text-primary me-3 mb-0 list-unstyled">
                        <li class="day font-weight-bold mb-2">13</li>
                        <li class="month font-weight-bold">OCT</li>
                      </ul>
                      <div class="media-body content">
                        <h4><a href="javascript:void(0)" class="text-dark title">Business Conference for
                            professional</a></h4>
                        <p class="text-muted location-time"><span class="text-dark h6">Address:</span> Hall 4,
                          Gwangsan-gu, Kwangju, <span class="text-danger">South Korea</span> <br> <span
                            class="text-dark h6">Time:</span> 01:00PM to 02:15PM</p>
                        <a [ngxScrollTo]="'#tickets'" class="btn btn-sm btn-outline-primary mouse-down">Buy Ticket</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>

      </ul>
      <div [ngbNavOutlet]="nav" class="col-12 mt-4 pt-2"></div>

    </div>


  </div>
</section> -->
<!--end section-->
<!-- Schedule End -->

<!-- CTA Start -->
<section class="section bg-cta" data-jarallax='{"speed": 0.5}' style="background: url('assets/images/event/eventoVideo.jpg') center center; background-size: 100% 200%;" id="cta">
    <div class="bg-overlay bg-primary bg-gradient" style="opacity: 0.8;"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title">
                    <h1 class=" title-dark mb-4" style="color: blue;">Los eventos que organizamos en Buena Vista Máncora</h1>
                    <h3 style="font-size: x-large; color: blue;">Contacte con nosotros para poder organizar los eventos de su interes</h3>
                    <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" class="play-btn border border-light mt-4 video-play-icon">
                        <i-feather name="play" class="fea icon-ex-md text-white title-dark"></i-feather>
                    </a>
                    <ng-template #content let-modal>
                        <div class="modal-header">
                            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
                        </div>
                        <div class="modal-body">
                            <!-- <iframe src="https://player.vimeo.com/video/12022651" height="450" width="780" frameborder="0"
                allowfullscreen allow="autoplay; encrypted-media"></iframe> -->
                            <iframe src="https://www.youtube.com/embed/4CkglWtdtn8" width="780" height="415" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </ng-template>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- CTA End -->

<!-- Price Start -->
<!-- <section class="section" id="tickets">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Our Tickets Rates</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-md-4 col-12 mt-4 pt-2">
        <div class="card pricing-rates best-plan bg-light text-center border-0 rounded">
          <div class="card-body py-5">
            <img src="assets/images/icon/ticket1.svg" class="mb-4" height="50" alt="">
            <h6 class="title text-uppercase fw-bold mb-4">1 Day Tickets</h6>
            <div class="d-flex justify-content-center mb-4">
              <span class="h4 mb-0 mt-2">$</span>
              <span class="price h1 mb-0">09</span>
              <span class="h4 align-self-end mb-1">/Day</span>
            </div>

            <p class="text-muted">Start working with Landrick that can provide everything you need to generate
              awareness, drive traffic, connect.</p>
            <a href="javascript:void(0)" class="btn btn-outline-primary btn-sm mt-4">Buy Tickets</a>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-12 mt-4 pt-2">
        <div class="card pricing-rates best-plan text-center border-0 shadow rounded">
          <div class="ribbon ribbon-right ribbon-warning overflow-hidden"><span
              class="text-center d-block shadow small h6">Best</span></div>
          <div class="card-body py-5">
            <img src="assets/images/icon/ticket2.svg" class="mb-4" height="50" alt="">
            <h6 class="title text-uppercase fw-bold mb-4">Full Tickets</h6>
            <div class="d-flex justify-content-center mb-4">
              <span class="h4 mb-0 mt-2">$</span>
              <span class="price h1 mb-0">24</span>
              <span class="h4 align-self-end mb-1">/Full</span>
            </div>

            <p class="text-muted">Start working with Landrick that can provide everything you need to generate
              awareness, drive traffic, connect.</p>
            <a href="javascript:void(0)" class="btn btn-outline-primary btn-sm mt-4">Buy Tickets</a>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-12 mt-4 pt-2">
        <div class="card pricing-rates best-plan bg-light text-center border-0 rounded">
          <div class="card-body py-5">
            <img src="assets/images/icon/ticket3.svg" class="mb-4" height="50" alt="">
            <h6 class="title text-uppercase fw-bold mb-4">Couple Tickets</h6>
            <div class="d-flex justify-content-center mb-4">
              <span class="h4 mb-0 mt-2">$</span>
              <span class="price h1 mb-0">16</span>
              <span class="h4 align-self-end mb-1">/Day</span>
            </div>

            <p class="text-muted">Start working with Landrick that can provide everything you need to generate
              awareness, drive traffic, connect.</p>
            <a href="javascript:void(0)" class="btn btn-outline-primary btn-sm mt-4">Buy Tickets</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Events Review</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-lg-9 mt-4 pt-2 text-center">
        <app-review-testmonial [reviewData]="reviewData"></app-review-testmonial>
      </div>
    </div>
  </div>
</section> -->
<!--end section-->
<!-- Price End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-light">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
    </div>
</div>
<!--Shape End-->

<!-- News Start -->
<section class="section bg-light">
    <div class="container">
        <!-- <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Nuestros ultimos eventos en Buena Vista Máncora</h4>
          <p class="text-muted para-desc mx-auto mb-0" style="font-size: x-large;">En <span
            class="text-primary fw-bold">Buena Vista Máncora</span> Lorem Ipsum is simply dummy text of the printing and typesetting 
            industry Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
        </div>
      </div>
    </div> -->
        <app-cartillas [cartillaData]="cartillaData"></app-cartillas>
    </div>

    <!-- <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Sign up for our Newsletter</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-lg-7 col-md-10">
        <div class="text-center subcribe-form mt-4 pt-2">
          <form>
            <input name="email" id="email" type="email" class="form-control rounded-pill" placeholder="Your email :"
              required aria-describedby="newssubscribebtn">
            <button class="btn btn-pills btn-primary submitBnt" type="submit" id="newssubscribebtn">Subscribe</button>
          </form>
        </div>
      </div>
    </div>
  </div> -->
</section>
<!--end section-->
<!-- End News -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-footer">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
    </div>
</div>
<!--Shape End-->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->