// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // url_base_backend: 'http://localhost:8000/api',
  // url_root_backend: 'http://localhost:8000',
  // url_root_frontend: 'http://localhost:4200',
  url_base_backend: '//reserva.buenavistamancora.com/api',
  url_root_backend: '//reserva.buenavistamancora.com',
  url_root_frontend: '//www.buenavistamancora.com',
  url_pago:'http://localhost:9100',
  key_acceso_backend: 'Rdt6uToQ.10xQDsISuxkIHMGvs4ZOFRt18Is8GEta'
  //key_acceso_backend: '1KsNX853.fuxE5SoFwVmRowb3gnrx5tyzHTL8R59h'
  //ultima modificacion Ronald

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
